<template>
  <v-card flat>
    <v-card-title class="text-h5">
      URL, Ambiente y Respuesta
    </v-card-title>
    <v-divider> </v-divider>
    <v-card-text>
      <p>
        Para interactuar con la API de nuestra plataforma, es importante
        comprender cómo se estructuran las URLs de los endpoints. Cada llamada a
        la API debe realizarse hacia una URL que se compone de tres partes
        principales: el <strong>Ambiente</strong>, el
        <strong> prefijo </strong>
        `/corporate/api_partner/`, y finalmente, el
        <strong> recurso específico </strong> de la API que se desea acceder.
      </p>
      <p>
        El <strong>"Ambiente"</strong> se refiere al entorno al que estás
        apuntando con tu solicitud. Por ejemplo, podría ser un entorno de
        prueba, o de producción, y cambiará la base de la URL según el caso.
        Esto es crucial para asegurar que tus pruebas o integraciones funcionen
        correctamente en el contexto deseado.
      </p>
      <p>La estructura general de una URL de endpoint se verá así:</p>
      <DocumentationCodeSnippetVue>
        <pre>`${Ambiente}/corporate/api_partner/${recurso_de_la_api}`</pre>
      </DocumentationCodeSnippetVue>
      <p >
        Donde <strong>${Ambiente}</strong> es la base de la URL que indica el
        entorno específico al que estás accediendo (por ejemplo,
        "https://beta.ridery.app" para pruebas o "https://ridery.app" para
        producción), y <strong> ${recurso_de_la_api} </strong> se refiere al
        endpoint específico que deseas utilizar
      </p>
      <p>
        <strong> Ambientes: </strong>
      </p>
      <p>
        <v-simple-table dense>
          <template #default>
            <thead>
              <tr>
                <th class="text-left">Ambiente</th>
                <th class="text-left">URL</th>
                <th class="text-left">Descipción</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-left">Beta</td>
                <td class="text-left">https://beta.ridery.app</td>
                <td class="text-left">
                  Ambiente para realizar pruebas de desarrollo e integración
                </td>
              </tr>
              <tr>
                <td class="text-left">Producción</td>
                <td class="text-left">https://ridery.app</td>
                <td class="text-left">Ambiente para producción</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </p>
      <p>
        Es fundamental asegurarse de construir correctamente estas URLs para
        cada solicitud, utilizando el ambiente apropiado y el recurso específico
        que se necesita acceder, para garantizar la efectividad de las
        interacciones con la API.
      </p>
      <p><strong>Respuesta:</strong></p>
      <p>
        <strong>TODAS</strong> las respuestas de la API van a poseer un
        atributo/llave si nombre <code>success</code>. Este atributo es un
        booleano que indica si la respuesta fue correcta o incorrecta de la
        petición
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentationCodeSnippetVue from '../../componentes/DocumentationCodeSnippet.vue';
export default {
  components: { DocumentationCodeSnippetVue },
};
</script>
<style lang="scss" scoped></style>
