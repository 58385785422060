<template>
  <v-card flat>
    <v-card-title class="text-h5">Webhook</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <p>
        Nuestra API cuenta con un servicio de notificaciones de cambio de
        estados de un viaje/envío creado, el cual notifica al usuario del cambio
        de estado de un viaje en curso vía <strong>Webhook</strong>
      </p>
      <p>
        Para crear un webhook, primero es necesario definir una URL de destino.
        Esta URL es el punto de conexión al cual el servidor enviará los datos
        mediante una solicitud
        <strong>HTTP POST</strong> cada vez que ocurra el evento que el webhook
        está monitoreando. En este caso, cambios de estado de un viaje
      </p>

      <p>
        La configuración del webhook se realiza generalmente a través del
        <router-link to="/api-credentials">módulo de credenciales</router-link>.
        Aquí deberás proporcionar la URL (válida) del endpoint que has preparado
        para recibir los datos del webhook.
      </p>

      <p>
        Una vez que el webhook esté configurado y el evento monitoreado ocurra,
        recibirás los datos en el cuerpo (body) de la solicitud POST. Estos
        datos estarán en formato JSON y contienen información detallada sobre el
        estado de un viaje en particular.
      </p>
      <p><strong>Ejemplo:</strong></p>

      <p>
        Cuando se recibe una notificación a través del webhook, el body de la
        solicitud HTTP contendrá el ID único del viaje y el estado actual del
        viaje en el siguiente formato:
      </p>

      <DocumentationCodeSnippetVue>
        <pre>
 {  
    "unique_id": ${unique_id},
    "content": "TRIP ID: ${unique_id}; TRIP_STATUS: ${trip_status}",
    "trip_status": ${trip_status},
    ...
  }</pre
        >
      </DocumentationCodeSnippetVue>

      <p>
        La variable <code>${unique_id}</code> representa el ID único del viaje
        que concierne
      </p>

      <p>
        La variable <code>${trip_status}</code> representa el estado del viaje
        al momento de emitir el webhook
      </p>

      <p><strong>Estados:</strong></p>

      <p>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">Estado</th>
              <th class="text-left">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>DRIVER_ACCEPTED_TRIP</td>
              <td>El conductor ha aceptado el envío/viaje.</td>
            </tr>
            <tr>
              <td>DRIVER_IN_ROUTE</td>
              <td>El conductor está en ruta hacia la ubicación de recogida.</td>
            </tr>
            <tr>
              <td>DRIVER_ARRIVED</td>
              <td>El conductor ha llegado a la ubicación de recogida.</td>
            </tr>
            <tr>
              <td>DRIVER_STARTED_TRIP</td>
              <td>El envío/viaje ha comenzado.</td>
            </tr>
            <tr>
              <td>DRIVER_COMPLETED_TRIP</td>
              <td>El envío/viaje se ha completado exitosamente.</td>
            </tr>
            <tr>
              <td>DRIVER_NOT_FOUND_CANCELLED_TRIP</td>
              <td>
                No se encontró conductor, el envío/viaje ha sido cancelado.
              </td>
            </tr>
            <tr>
              <td>USER_CANCELLED_TRIP</td>
              <td>El envío/viaje fue cancelado por el usuario.</td>
            </tr>
            <tr>
              <td>DRIVER_CANCELLED_TRIP</td>
              <td>El envío/viaje fue cancelado por el conductor.</td>
            </tr>
            <tr>
              <td>ADMIN_CANCELLED_TRIP</td>
              <td>El envío/viaje fue cancelado por un administrador.</td>
            </tr>
            <tr>
              <td>DISPATCHER_CANCELLED_TRIP</td>
              <td>El envío/viaje fue cancelado por un dispatcher.</td>
            </tr>
            <tr>
              <td>CORPORATE_CANCELLED_TRIP</td>
              <td>El envío/viaje fue cancelado por un corporate.</td>
            </tr>
            <tr>
              <td>CANCELLED_TRIP</td>
              <td>El envío/viaje fue cancelado.</td>
            </tr>
          </tbody>
        </v-simple-table>
      </p>

      <p>Este JSON es enviado al endpoint configurado para el webhook</p>

      <p>
        Desde el lado de Ridery se espera una respuesta de tipo
        <code>200</code> hacia donde envíe el Webhook
      </p>

      <p>
        <strong>No hay reintento de Webhook</strong> si no se obtiene un
        <code>200</code> de parte de tu servidor
      </p>

      <p>
        Finalmente, tu servidor o el endpoint configurado debe procesar la
        información recibida según las necesidades de tu aplicación. Esto podría
        incluir la actualización de bases de datos, la activación de procesos
        internos o la integración con otros recursos de la API de Ridery.
      </p>

      <p>
        De ser necesario, también se puede <strong>desactivar</strong> el
        webhook configurando accediendo al
        <router-link to="/api-credentials">módulo de credenciales</router-link>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentationCodeSnippetVue from "../../componentes/DocumentationCodeSnippet.vue";

export default {
  components: { DocumentationCodeSnippetVue },
};
</script>
<style lang="scss" scoped></style>
