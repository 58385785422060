<template>
  <v-tabs
    v-model="tab"
    :vertical="$vuetify.breakpoint.mdAndUp"
    :show-arrows="!$vuetify.breakpoint.mdAndUp"
  >
    <v-tab v-for="tab in tabs" :key="tab.index">
      <span v-html="tab.title"></span>
    </v-tab>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.index">
        <Component :is="tab.component" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import ShipmentPrevias from "./parts/ShipmentPrevias.vue";
import GetFareEstimatesShipments from "./parts/GetFareEstimatesShipments.vue";
import CreateTrip from "./parts/CreateTrip.vue";
import RecentTrips from "./parts/RecentTrips.vue";
import TripDetail from "./parts/TripDetail.vue";
import CancelTrip from "./parts/CancelTrip.vue";

export default {
  components: {
    CodeHighlight,
    ShipmentPrevias,
    GetFareEstimatesShipments,
    CreateTrip,
    TripDetail,
  },

  data() {
    return {
      tab: 0,
      tabs: [
        {
          title: "Previas",
          component: ShipmentPrevias,
        },
        {
          title: "get_fare_estimates_shipments",
          component: GetFareEstimatesShipments,
        },
        {
          title: "create_trip",
          component: CreateTrip,
        },
        {
          title: "recent_trips",
          component: RecentTrips,
        },
        {
          title: "trip_detail",
          component: TripDetail,
        },
        {
          title: "cancel_trip",
          component: CancelTrip,
        },
      ],
    };
  },
};
</script>
<style lang=""></style>
