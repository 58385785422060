<template>
  <!-- Accesos y permisos -->
  <v-card flat>
    <v-card-title class="text-h5">Accesos y Permisos en la API</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-justify">
      <p>
        La <strong>gestión de accesos y permisos</strong> es crucial para
        garantizar la utilización segura y eficiente de los recursos de una API.
        Es esencial controlar tanto los accesos globales como los particulares
        para proteger tanto tu seguridad como la nuestra.
      </p>
      <p>
        Ridery tiene asignado un
        <strong>permiso de acceso global</strong> a todos sus API Partners,
        necesario para el uso efectivo de la API. Además, cada API Partner
        cuenta con permisos individuales para acceder a la API.
      </p>
      <p>
        Asimismo, se establece un permiso específico para el acceso a la API de
        creación de envíos.
      </p>
      <p>
        Todos estos permisos se pueden verificar en las
        <router-link to="/api-credentials">credenciales de la API</router-link>.
        Es vital implementar una política de permisos clara que especifique los
        accesos adecuados para cada función. Esto previene el acceso no
        autorizado y garantiza que cada API Partner disponga únicamente del
        nivel de acceso necesario para sus operaciones.
      </p>
      <p>
        Si al momento de una petición el API Partner no posee los permisos
        específicos a un recurso o si el acceso a API Partners se encuentra
        desactivado, el servidor responderá al usuario con un error de tipo
        <code>4XX</code>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped></style>
