<template>
  <v-card flat>
    <v-card-title class="text-h5">Token y autorización en la API</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-justify">
      <p>
        La utilización de <strong>tokens</strong> y sistemas de
        <strong>autorización</strong> es esencial en la seguridad y gestión de
        APIs. Estos mecanismos aseguran que sólo los usuarios y aplicaciones
        autorizados puedan acceder a los recursos y servicios ofrecidos por la
        API.
      </p>
      <p>
        Es necesario enviar un <strong>token de acceso</strong> en cada petición
        a realizar a la API. Este token se puede consultar y generar en el
        módulo de
        <router-link to="/api-credentials">credenciales de la API</router-link>
      </p>
      <p>
        Una vez consultado o generado el mismo, es esencial incluir el
        <strong>token de autorización</strong> en cada petición. Este token debe
        ser enviado a través del header <code>'authorization'</code> utilizando
        el formato <code>Bearer ${token}</code>, donde <code>${token}</code> es
        el
        <strong>token de autorización</strong>
      </p>
      <p>
        En caso de omitir el token de autorización o si el token proporcionado
        es inválido o ha expirado, la API responderá con un error de
        <strong>No Autorizado</strong> <code>401</code>. Este error indica que
        la petición no ha sido completada debido a credenciales inválidas o
        ausentes.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped></style>
