<template>
  <v-card flat>
    <v-card-title class="text-h5">Previas</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-justify">
      <p>
        Antes de proceder con cualquier envío utilizando la API de Ridery, es
        crucial tener en cuenta varias consideraciones previas para asegurar una
        integración exitosa y el cumplimiento de las políticas de uso. En primer
        lugar, se recomienda encarecidamente revisar la documentación técnica
        proporcionada. Esta contiene información detallada sobre los endpoints
        disponibles, los parámetros que cada uno acepta, y los formatos de
        respuesta esperados.
      </p>

      <p>
        Además,
        <strong> es fundamental hacer un uso correcto de la API </strong>, no
        realizar llamadas duplicadas, no saturar los endpoints de la misma, etc.
      </p>

      <p>
        Uno de los aspectos más importantes a considerar antes de realizar
        cualquier envío es la lectura y comprensión de los
        <strong> términos y condiciones</strong> de los mismos. Estos términos
        delinean el uso correcto del servicio. Asegúrate de que su uso por API
        esté en plena conformidad. Lo ideal es mantener una relación de trabajo
        positiva con Ridery y evitar posibles suspensiones de uso de la API.
      </p>

      <p>
        <router-link to="/parcel-guide" target="_blank">
          Términos y Condiciones
        </router-link>
      </p>

      <p>
        Por último, se aconseja realizar pruebas en un entorno de desarrollo
        antes de pasar a producción. Esto incluye verificar la correcta
        manipulación de errores, la validación de datos de entrada, y la
        implementación de mecanismos de reintento adecuados y mesurados.
        Prepararse de antemano para gestionar estos aspectos puede marcar la
        diferencia en la fiabilidad y la eficiencia de las integraciones con la
        API de Ridery.
      </p>

      <p>
        Una vez establecido lo anterior, se procede a explicar cada endpoint
        necesario para crear un envío satisfactoriamente utilizando la API de
        Ridery
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
export default {
  components: { CodeHighlight },
};
</script>
<style lang="scss" scoped></style>
