<template>
  <v-card flat>
    <v-card-title class="text-h5">create_trip</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-justify">
      <p>
        Para iniciar un nuevo envío, se debe enviar una solicitud
        <strong>POST</strong> al endpoint <code>/create_trip</code>. Esta
        solicitud debe incluir por <strong>body</strong> detalles como las
        coordenadas de origen y destino, direcciones explícitas para
        visualización en la aplicación, información sobre el tipo de ciudad,
        datos del responsable del envío, descripción del paquete, e
        instrucciones específicas para el origen y destino.
      </p>
      <p>
        A continuación se muestra un ejemplo de payload que se debe enviar en la
        solicitud:
      </p>
      <DocumentationCodeSnippetVue language="javascript">
        <pre>
      {
        "origin_latitude": "10.4416174", //Wave Tech Hub
        "origin_longitude": "-66.8677564",
        "origin_address": "Wave Tech Hub",
        "destination_latitude": "10.4968747", //Plaza Venezuela
        "destination_longitude": "-66.888577",
        "destination_address": "Plaza Venezuela",
        "city_type_id": "62bf1af16b60baf9ac4c27e3", //Id de servicio seleccionado previamente en /get_fare_estimates_shipments
        "responsible_first_name": "Pedro", // Nombre del responsable del envío
        "responsible_last_name": "Pérez", // Apellido del responsable del envío
        "responsible_phone": "4241231231", // Teléfono celular del responsable del envío
        "package_description": "Caja de chocolates", // Descripción del paquete a enviar
        "source_instructions": "Origen instrucciones", // Instrucciones en la recogida
        "destination_instructions": "Destino instrucciones" // Instrucciones en la entrega
      }</pre
        >
      </DocumentationCodeSnippetVue>

      <p class="mt-8">
        <strong> Detallado: </strong>
      </p>

      <p>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Clave</th>
                <th class="text-left">Valor / Descripción</th>
                <th class="text-left">Tipo de dato</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>origin_latitude</td>
                <td>10.4416174 (Wave Tech Hub, ejemplo)</td>
                <td>String</td>
              </tr>
              <tr>
                <td>origin_longitude</td>
                <td>-66.8677564</td>
                <td>String</td>
              </tr>
              <tr>
                <td>origin_address</td>
                <td>Wave Tech Hub (La dirección de origen)</td>
                <td>String</td>
              </tr>
              <tr>
                <td>destination_latitude</td>
                <td>10.4968747 (Plaza Venezuela)</td>
                <td>String</td>
              </tr>
              <tr>
                <td>destination_longitude</td>
                <td>-66.888577</td>
                <td>String</td>
              </tr>
              <tr>
                <td>destination_address</td>
                <td>Plaza Venezuela (La dirección de destino)</td>
                <td>String</td>
              </tr>
              <tr>
                <td>city_type_id</td>
                <td>
                  62bf1af16b60baf9ac4c27e3 (Id de servicio seleccionado
                  previamente en /get_fare_estimates_shipments)
                </td>
                <td>String</td>
              </tr>
              <tr>
                <td>responsible_first_name</td>
                <td>Pedro (Nombre del responsable del envío)</td>
                <td>String</td>
              </tr>
              <tr>
                <td>responsible_last_name</td>
                <td>Pérez (Apellido del responsable del envío)</td>
                <td>String</td>
              </tr>
              <tr>
                <td>responsible_phone</td>
                <td>
                  4241231231 (Teléfono celular del responsable del envío, en ese
                  formato)
                </td>
                <td>String</td>
              </tr>
              <tr>
                <td>package_description</td>
                <td>Caja de chocolates (Descripción del paquete a enviar)</td>
                <td>String</td>
              </tr>
              <tr>
                <td>source_instructions</td>
                <td>Origen instrucciones (Instrucciones en la recogida)</td>
                <td>String</td>
              </tr>
              <tr>
                <td>destination_instructions</td>
                <td>Destino instrucciones (Instrucciones en la entrega)</td>
                <td>String</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </p>

      <p>
        Una vez procesada la solicitud, la API responderá con un objeto JSON que
        indica el éxito de la operación y proporciona un identificador único
        para el viaje creado. La respuesta típica es la siguiente:
      </p>
      <DocumentationCodeSnippetVue language="javascript">
        <pre>
      {
        "success": true,
        "trip": {
          "unique_id": 54088
        }
      }</pre
        >
      </DocumentationCodeSnippetVue>
      <p>
        El <code>unique_id</code> del viaje es un valor importante que se debe
        registrar, ya que será necesario para futuras consultas o acciones
        relacionadas con este viaje específico. Asegúrate de manejar
        correctamente este <strong>identificador único</strong> en tu sistema.
      </p>

      <p>
        <strong>Cabe acotar que se hará un recálculo</strong> al recibir la
        solicitud de crear, y que éste <strong>pudiere diferir</strong> si
        suficiente tiempo ha pasado de la estimación inicial de
        <code>/get_fare_estimates_shipments</code>
      </p>
      <p>
        <strong>Errores posibles:</strong>
      </p>
      <p>
        <v-simple-table>
          <thead>
            <tr>
              <th class="text-left">Código de Error</th>
              <th class="text-left">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>"812"</td>
              <td>
                No hay conductores disponibles para el punto de origen
                disponible
              </td>
            </tr>
            <tr>
              <td>"997"</td>
              <td>No se dispone de suficiente balance corporativo</td>
            </tr>
          </tbody>
        </v-simple-table>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentationCodeSnippetVue from "../../componentes/DocumentationCodeSnippet.vue";

export default {
  components: { DocumentationCodeSnippetVue },
};
</script>
<style lang="scss" scoped></style>
