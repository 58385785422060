<template>
  <v-card flat>
    <v-card-title class="text-h5">get_fare_estimates_shipments</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-justify">
      <p>
        Para obtener una estimación de tarifas para envíos, es necesario
        realizar una solicitud <strong>POST</strong> al endpoint
        <code>/get_fare_estimates_shipments</code> con un payload (json) que
        incluya las coordenadas de origen y destino. A continuación, se muestra
        un ejemplo del payload requerido:
      </p>
      <DocumentationCodeSnippetVue>
        <pre>
      {
        "origin_latitude": "10.4416174", // Origen: Wave Tech Hub
        "origin_longitude": "-66.8677564",
        "destination_latitude": "10.4968747", // Destino: Plaza Venezuela
        "destination_longitude": "-66.888577"
       }</pre
        >
      </DocumentationCodeSnippetVue>

      <p>
        La respuesta correcta a esta solicitud proporcionará un
        <code>200</code> con un objeto JSON con un campo
        <code>success</code> indicando el éxito de la operación y un array
        <code>fare_estimates</code> que contiene las estimaciones de tarifa para
        diferentes servicios de envío disponibles según las coordenadas de
        origen proporcionadas. Cada objeto dentro de
        <code>fare_estimates</code> incluirá información sobre el tipo de
        servicio, la tarifa estimada, y detalles adicionales sobre el servicio.
      </p>
      <p>Ejemplo de respuesta:</p>

      <DocumentationCodeSnippetVue>
        <pre>
    {
      "success": true,
      "fare_estimates": [
        {
          "estimated_fare": 10.03,
          "city_type_id": "62bf1af16b60baf9ac4c27e3",
          "typename": "Envíos Moto",
          ...
        },
        {
          "estimated_fare": 15.03,
          "city_type_id": "6304eed60bb78f6407c7c904",
          "typename": "Envíos Económico",
          ...
        },
        ...
      ]
     }
  </pre
        >
      </DocumentationCodeSnippetVue>
      <p>
        Cada objeto en <code>fare_estimates</code> representa un servicio de
        envío disponible según la coordenada de origen enviada, incluyendo
        detalles como el espacio máximo disponible, la cantidad de equipaje
        permitida, descripciones del servicio tanto en inglés como en español y
        el <strong>costo del mismo (estimated_fare)</strong>.
      </p>
      <p>Errores</p>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentationCodeSnippetVue from "../../componentes/DocumentationCodeSnippet.vue";

export default {
  components: { DocumentationCodeSnippetVue },
};
</script>
<style lang="scss" scoped></style>
