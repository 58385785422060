<template>
  <v-card flat>
    <v-card-title class="text-h5">cancel_trip</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-justify">
      <p>
        Para cancelar un envío en curso, se debe enviar una solicitud POST al
        endpoint <code>/cancel_trip</code>. Esta solicitud requiere un objeto
        JSON en el cuerpo (body) que contenga el identificador único del envío
        que se desea cancelar. A continuación, se proporciona un ejemplo del
        payload necesario:
      </p>
      <DocumentationCodeSnippetVue language="javascript">
        <pre>
      {
        "trip_id": "54085" //ID del envío a cancelar
       }</pre
        >
      </DocumentationCodeSnippetVue>

      <p>
        Al procesar la solicitud de cancelación, el servidor responderá con un
        objeto JSON que confirma la operación. La estructura típica de la
        respuesta es la siguiente:
      </p>

      <DocumentationCodeSnippetVue language="javascript">
        <pre>
      {
        "success": true,
        "message": "Trip ID: 54085 cancelled",
        "trip": {
          unique_id: "ID del viaje cancelado"
        }
       }</pre
        >
      </DocumentationCodeSnippetVue>

      <p>
        La respuesta incluirá un campo <code>success</code> que indica si la
        solicitud fue exitosa, junto con un mensaje que confirma la cancelación
        del viaje y muestra el ID del viaje cancelado. Considerar manejar
        adecuadamente esta respuesta para informar al usuario sobre el estado de
        su solicitud de cancelación.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentationCodeSnippetVue from "../../componentes/DocumentationCodeSnippet.vue";

export default {
  components: { DocumentationCodeSnippetVue },
};
</script>
<style lang="scss" scoped></style>
