<template>
  <v-card flat>
    <v-card-title class="text-h5">trip_detail/:id</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-justify">
      <p>
        El endpoint <code>/trip_detail/:id</code> entrega información detallada
        sobre un viaje específico, accesible mediante una solicitud
        <strong>GET</strong>. El <code>:id</code> debe ser reemplazado por el
        identificador único del viaje/envío de interés.
      </p>
      <p>
        La respuesta incluye un objeto JSON con los campos
        <code>success</code> y <code>trip</code>, donde
        <code>success</code> indica el resultado de la operación y
        <code>trip</code> contiene los detalles del viaje. La estructura del
        objeto <code>trip</code> se explica a continuación:
      </p>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Campo</th>
              <th class="text-left">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>_id, unique_id</td>
              <td>Identificadores del viaje.</td>
            </tr>
            <tr>
              <td>total, invoice_number</td>
              <td>Costo total y número de factura del viaje.</td>
            </tr>
            <tr>
              <td>trip_details</td>
              <td>
                Detalles específicos del viaje, incluyendo estado, confirmación
                del proveedor, direcciones y ubicaciones de inicio y destino,
                tipo de servicio y más.
              </td>
            </tr>
            <tr>
              <td>user_details, provider_details</td>
              <td>
                Información sobre el usuario y el proveedor, incluidos nombres,
                teléfonos y, para el proveedor, su ubicación.
              </td>
            </tr>
            <tr>
              <td>trip_location</td>
              <td>
                Ubicaciones de inicio y fin del viaje y la ruta completa en
                formato de coordenadas.
              </td>
            </tr>
            <tr>
              <td>shipment</td>
              <td>
                Detalles del envío, como nombre del responsable, teléfono,
                descripción del paquete, instrucciones y una imagen del usuario.
              </td>
            </tr>
            <tr>
              <td>created_at</td>
              <td>Fecha y hora de creación del viaje.</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="mt-4">
        Ejemplo de la respuesta JSON para una solicitud GET a
        <code>/trip_detail/:id</code>, mostrando la información detallada del
        viaje:
      </p>
      <DocumentationCodeSnippetVue language="javascript">
        <pre>
      {
        "success": true,
        "trip": {
          "_id": "66033ff7ef444334ac848ec4",
          "unique_id": "54255",
          ...
          "trip_details": {
            ...
          },
          "user_details": {
            ...
          },
          "provider_details": {
            ...
          },
          "trip_location": {
            "start_trip_location": [10.434535026815055, -66.85979179761458],
            "end_trip_location": [10.43451589846691, -66.85977005241699],
            "path": [...],
            "map_full_path": "Objeto 'estringuificado' para uso en google maps, mapbox o similares"
          },
          "shipment": {
            ...
          },
          "created_at": "2024-03-26T21:36:55.493Z"
        }
      }
    </pre
        >
      </DocumentationCodeSnippetVue>
      <p>
        La respuesta detalla cada aspecto del viaje, facilitando el seguimiento
        exhaustivo y la gestión efectiva del mismo.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentationCodeSnippetVue from "../../componentes/DocumentationCodeSnippet.vue";

export default {
  components: { DocumentationCodeSnippetVue },
};
</script>
<style lang="scss" scoped></style>
