<template>
  <v-tabs
    v-model="tab"
    :vertical="$vuetify.breakpoint.mdAndUp"
    :show-arrows="!$vuetify.breakpoint.mdAndUp"
  >
    <v-tab v-for="tab in tabs" :key="tab.index">
      <div v-html="tab.title"></div>
    </v-tab>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in tabs" :key="tab.index">
        <Component :is="tab.component" />
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>
<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";

import AccessAndPermits from "./parts/AccessAndPermits.vue";
import TokenAndAuth from "./parts/TokenAndAuth.vue";
import BaseURLAmbientAnswer from "./parts/BaseURLAmbientAnswer.vue";
import Webhook from "./parts/Webhook.vue";
import Other from "./parts/Other.vue";

export default {
  components: {
    CodeHighlight,
    AccessAndPermits,
    TokenAndAuth,
    BaseURLAmbientAnswer,
    Webhook,
    Other,
  },
  data() {
    return {
      tab: 0,
      tabs: [
        {
          title: "Accesos y <br /> permisos",
          component: AccessAndPermits,
        },
        {
          title: "Token y <br /> autorización",
          component: TokenAndAuth,
        },
        {
          title: "URL, Ambiente y <br /> Respuesta",
          component: BaseURLAmbientAnswer,
        },
        {
          title: "Webhook",
          component: Webhook,
        },
        {
          title: "Otras <br /> consideraciones",
          component: Other,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
