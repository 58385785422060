<template>
  <v-card flat>
    <v-card-title class="text-h5">recent_trips</v-card-title>
    <v-divider></v-divider>
    <v-card-text class="text-justify">
      <p>
        El endpoint <code>/recent_trips</code> se utiliza para recuperar una
        lista de los viajes recientes (en curso) relacionados únicamente con
        envíos. Al realizar una solicitud <strong>GET</strong> a este endpoint
        con el query <code>?only_shipments=1</code>, se recibe un objeto de
        respuesta que indica el éxito de la solicitud y un arreglo de objetos,
        cada uno representando un viaje reciente con detalles específicos.
      </p>
      <p>
        La respuesta exitosa del endpoint incluye un campo
        <code>success</code> que indica si la solicitud fue exitosa y un arreglo
        <code>trips</code> con los detalles de cada viaje. Es
        <strong>importante</strong> enviar el query
        <code>?only_shipments=1</code> para obtener una respuesta exitosa.
      </p>
      <p>
        A continuación, se describen los campos más relevantes incluidos en cada
        objeto del arreglo <code>trips</code>:
      </p>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Campo</th>
              <th class="text-left">Descripción</th>
            </tr>
          </thead>
          <tbody>
            <!-- Campos comunes -->
            <tr>
              <td>_id</td>
              <td>Identificador único del viaje.</td>
            </tr>
            <tr>
              <td>unique_id</td>
              <td>Identificador único legible para el viaje.</td>
            </tr>
            <tr>
              <td>total</td>
              <td>Costo total del viaje.</td>
            </tr>
            <tr>
              <td>invoice_number</td>
              <td>Número de factura del viaje.</td>
            </tr>
            <tr>
              <td>created_at</td>
              <td>Fecha y hora de creación del viaje.</td>
            </tr>
            <!-- Detalles específicos del viaje -->
            <tr>
              <td>trip_details</td>
              <td>Objeto con detalles específicos del viaje.</td>
            </tr>
            <tr>
              <td>user_details</td>
              <td>Detalles del usuario que solicita el viaje.</td>
            </tr>
            <tr>
              <td>provider_details</td>
              <td>Información sobre el proveedor del servicio.</td>
            </tr>
            <tr>
              <td>shipment_details</td>
              <td>Detalles específicos del envío asociado al viaje.</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p class="mt-2">
        Aquí se muestra un ejemplo de cómo podría verse un objeto dentro del
        arreglo <code>trips</code> de la respuesta:
      </p>
      <DocumentationCodeSnippetVue language="javascript">
        <pre>
    {
      "success": true,
      "trips": [
        {
          "_id": "6604330135b908d7bc0efdd5",
          "unique_id": "54336",
          ...
          "shipment_details": {
            "responsible_full_name": "Daniel Morán",
            ...
          },
          "created_at": "2024-03-27T14:53:53.165Z"
        }
      ]
    }
    </pre
        >
      </DocumentationCodeSnippetVue>
      <p>
        Este endpoint es especialmente útil para monitorear y gestionar los
        viajes recientes de envíos que se encuentren en curso, facilitando el acceso a información clave
        necesaria para un seguimiento y administración efectivos. Si no hay
        envíos en curso, el arreglo <code>trips</code> estará vacío.
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import DocumentationCodeSnippetVue from "../../componentes/DocumentationCodeSnippet.vue";

export default {
  components: { DocumentationCodeSnippetVue },
};
</script>
<style lang="scss" scoped></style>
