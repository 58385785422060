<template>
  <div class="code-highlight-container mb-2" ref="codeContainer">
    <v-tooltip top v-model="showTooltip" color="primary">
      <template v-slot:activator="{ attrs }">
        <v-btn
          class="copy-btn"
          v-bind="attrs"
          @click="copyToClipboard"
          color="white"
          icon
          small
        >
          <v-icon small style="color: #ffffff !important">
            {{ icons.mdiContentCopy }}
          </v-icon>
        </v-btn>
      </template>
      <span> Contenido copiado </span>
    </v-tooltip>
    <CodeHighlight :language="language">
      <slot></slot>
    </CodeHighlight>
  </div>
</template>

<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
import "vue-code-highlight/themes/window.css";
// import "vue-code-highlight/themes/prism.css";

import { mdiContentCopy } from "@mdi/js";

export default {
  name: "DocumentationCodeSnippet",
  components: {
    CodeHighlight,
  },
  props: {
    language: {
      type: String,
      default: "javascript",
    },
  },
  data() {
    return {
      icons: {
        mdiContentCopy,
      },
      showTooltip: false,
      timerId: 0,
    };
  },
  methods: {
    copyToClipboard() {
      try {
        const range = document.createRange();
        range.selectNodeContents(this.$refs.codeContainer.querySelector("pre"));
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
        selection.removeAllRanges();

        this.showTooltip = true;
        this.timerId = setTimeout(() => {
          this.showTooltip = false;
        }, 700);
      } catch (error) {
        console.error(error);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timerId);
  },
};
</script>

<style lang="scss" scoped>
.code-highlight-container {
  position: relative;
}

.copy-btn {
  margin-top: 11px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}
</style>
