<template>
  <v-card flat>
    <v-card-title class="text-h5">Otras consideraciones</v-card-title>
    <v-divider> </v-divider>
    <v-card-text>
      <p>
        Todos los viajes/envíos se pueden monitorear en sus respectivos módulos
        dentro del <strong>Panel Corporativo</strong>
      </p>
      <p>
        Todo viaje/envío creado a través de API Partner o Panel Corporativo,
        igualmente puede ser monitoreado por el usuario ingresando a la app
        móvil de <strong>Ridery</strong>
      </p>
      <p>
        <router-link to="/profile">En tu vista de perfil</router-link>
        tienes a disposición varios contactos en caso de alguna inquietud o
        incidencia
      </p>
      <p>
        <strong>Toda</strong> creación de viaje/envío depende de que poseas
        suficiente <strong>Balance Corporativo</strong> en tu Panel`para saldar
        el costo del mismo, si deseas hacer una recarga puedes hacerlo a través
        del
        <router-link to="/balance"> módulo de balance</router-link>
      </p>
    </v-card-text>
  </v-card>
</template>
<script>
import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
import "vue-code-highlight/themes/duotone-sea.css";
export default {
  components: {
    CodeHighlight,
  },
};
</script>
<style lang="scss" scoped></style>
