<template>
  <div class="d-flex flex-column justify-content-between">
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-5">
          <v-row dense>
            <v-col
              cols="12"
              class="d-flex flex-row justify-md-start justify-center align-center"
            >
              <span class="d-flex flex-row justify-start align-center">
                <h2>{{ $t("api_documentation") }}</h2>
                <v-tooltip top :max-width="400">
                  <template #activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      medium
                      v-on="on"
                      class="ml-6 color-gray-text"
                    >
                      {{ icons.mdiInformationOutline }}
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("api_documentation_tooltip") }}
                  </span>
                </v-tooltip>
              </span>
              <span class="ml-6"
                >Última modificación: <strong>2024-03-27</strong></span
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <br />
    <v-row dense>
      <v-col cols="12">
        <v-row dense class="d-flex align-center py-0 my-0">
          <v-col cols="12" class="d-flex align-center">
            <v-tabs v-model="tab">
              <v-tabs-slider color="primary"></v-tabs-slider>
              <!-- Tabs header -->
              <v-tab v-for="tab in tabs" :key="tab.index">
                <div v-html="tab.title"></div>
              </v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <br />
    <v-row dense>
      <v-col cols="12">
        <v-card class="pa-2">
          <!-- Tab items -->
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="tab in tabs" :key="tab.index">
              <Component :is="tab.component" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Preliminaries from "./partials/Preliminaries.vue";
import Shipment from "./partials/Shipment.vue";
import {
  mdiInformationOutline,
  mdiMagnify,
  mdiCalendar,
  mdiDotsVertical,
  mdiDownload,
  mdiPlus,
} from "@mdi/js";
export default {
  components: { Preliminaries, Shipment },
  data() {
    return {
      icons: {
        mdiInformationOutline,
      },
      selected: "preliminaries",
      tabs: [
        {
          title: "Preliminares",
          component: Preliminaries,
        },
        {
          title: "Envíos",
          component: Shipment,
        },
      ],
      tab: 0,
    };
  },
};
</script>
<style lang="scss" scoped></style>
